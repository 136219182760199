<template>
  <div class="pa-2">
    <div class="d-flex align-center pb-2">
        <div class="display-1">Units</div>
      <v-spacer></v-spacer>
      <v-btn @click="openCreateUnitModal" color="primary">
        Create Unit
      </v-btn>
    </div>

    <v-card>
      <se2a-data-list
        resource="units"
        :headers="headers"
        :filters="filters"
      >
        <template #item.actions="{ item }">
          <v-btn :to="{name: 'se2a-admin-unit-details', params: {id: item.id}}" icon>
            <v-icon>mdi-open-in-new</v-icon>
          </v-btn>
        </template>
      </se2a-data-list>
    </v-card>
  </div>
</template>

<script>
import {defineComponent} from "vue";
import {CLIENT} from "@/api/client";
import {byApi} from "@/api/helpers";
import Intranet from "@/core/intranet";
import Se2aDataList from "@/components/se2a-data-list.vue";

export default defineComponent({
  components: {Se2aDataList},
  data: () => {
    return {
      headers: [
        {text: 'Id', value: 'id'},
        {text: 'Project Phase', value: 'projectPhase'},
        {text: 'Short Name', value: 'shortName'},
        {text: 'Title', value: 'title'},
        {text: 'Actions', value: 'actions'}
      ],
      filters: [
        {name: 'Short Name', on: 'shortName'},
        {name: 'Title', on: 'title'}
      ]
    }
  },
  methods: {
    openCreateUnitModal() {
      Intranet.modals().openModal('se2a-admin-unit-create-modal', {});
    }
  }
});
</script>

<style scoped>

</style>
